export const compare = (comparison, target, value) => {
  const [min, max] = target;
  value = Number(value);
  if (comparison == "between") {
    if (min < value && value < max) {
      return true;
    }
  }
  if (comparison == "<") {
    if (value < min) {
      return true;
    }
  }
  if (comparison == ">") {
    if (value > min) {
      return true;
    }
  }
  if (comparison == "<=") {
    if (value <= min) {
      return true;
    }
  }
  if (comparison == ">=") {
    if (value >= min) {
      return true;
    }
  }
  if (comparison == "==") {
    if (value === min) {
      return true;
    }
  }
};