<template>
  <div class="dynamic-form-edit">
    <dynamic-form-with-flows
      v-model="formData"
      :approveDataId="approveDataId"
      :excludeApprovalId="excludeApprovalId"
      :approveCode="approveCode"
      @update="saveDraft"
      ref="form"
    ></dynamic-form-with-flows>
    <div class="operations">
      <el-button @click="back" size="medium">返回</el-button>
      <el-button v-if="!modifyId" @click="reset" size="medium">重置</el-button>
      <el-button @click="submit" type="primary" size="medium" class="btn-reset"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import DynamicFormWithFlows from "@/businessComponents/dynamicFormWithFlows.vue";
export default {
  name: "DynamicFormEdit",
  components: { DynamicFormWithFlows },
  data() {
    return {
      approveCode: '',
      excludeApprovalId: '',
      approveDataId: "",
      formData: null,
      draftId: "",
      processId:"",
      modifyId:"",
      noProcessObj:{}
    };
  },
  created() {
    this.modifyId = this.$route.params.id;
    this.processId = this.$route.params.processId
    this.approveDataId = this.$route.query.approveDataId
    if (this.modifyId && !this.processId) {
      this.excludeApprovalId = this.modifyId
      this.getDetail(this.modifyId);
    }else if(this.modifyId && this.processId){
      this.getNoProcessDetail()
    } else {
      this.getDraft();
    }
  },
  methods: {
    getDetail(id) {
      this.$api.dynamicForm.getDetail(id).then(async ({ code, data }) => {
        if (code == 200) {
          this.compare(data)
        }
      });
    },
    getNoProcessDetail(){
      this.$api.dynamicForm.getFormDetail(this.$route.params.processId).then(({ code, data })=>{
        if (code == 200) {
          this.noProcessObj = data
          this.compare(data)
        }
      })
    },
    async compare(data){
      let lastestFormData = await this.getForm()
      // 判断最新表单的basic.id和当前的menu的formId是否相同 如果不相同 则需要返回并刷新页面，并让用户重新进入
      if(lastestFormData.basic.id==this.$route.params.formId){
        if(lastestFormData.basic.id==data.content.basic.id){
          this.formData = data.content;
        }else{
          this.$message.warning('您发起的流程表单已被修改，之前数据被清空！')
          this.formData = lastestFormData
        }
      }else{
        this.$message.warning('您发起的流程已被修改，请重试！')
        this.reloadPath();
      }
    },

    reloadPath(){
     // 跳到当前模块的第一个子页面
    const MenuList = JSON.parse(localStorage.getItem("menuList"));
     let firstChildMenu = MenuList.find(ele=>ele.meta && ele.meta.id==221);
      const pathName = firstChildMenu.redirect
      setTimeout(()=>{
        location.hash = `#${pathName}`;
        location.reload();
      },1500)
    },
    back() {
      this.$router.go(-1);
    },
    saveDraft() {
      if (this.modifyId) {
        return;
      }
      let p,
        params = {
          basicId: this.formData.basic.id,
          content: this.formData,
        };
      if (this.draftId) {
        p = this.$api.dynamicForm.update({
          ...params,
          id: this.draftId,
        });
      } else {
        p = this.$api.dynamicForm.insert(params);
      }
      p.then(({ code, data }) => {
        if (code == 200) {
          this.draftId = data?data.id:this.draftId;
        }
      });
    },
    getDraft() {
      const formId = this.$route.params.formId;
      // 先用菜单传过来的basicId获取表单详情，如果表单详情的id和菜单传过来的basicId相同，则根据菜单传过来的basicId再去获取草稿，否则，直接使用表单详情数据更恶心表单
      this.$api.dynamicForm
        .getUserDraft(formId)
        .then(async({ code, data: { id, content } }) => {
          if (code == 200) {
            let lastestFormData = await this.getForm()
            if (id) {  // 有草稿
              // 比较版本
              if(lastestFormData.basic.id==formId){
                if(lastestFormData.basic.id==content.basic.id){
                  this.draftId = id;
                  this.formData = content;
                }else{
                  this.$message.warning('您发起的流程表单已被修改，之前数据被清空！')
                  this.draftId = 0;
                  this.formData = lastestFormData
                }
              }else{
                this.$message.warning('您发起的流程已被修改，请重试')
                this.reloadPath()
              }
            } else {
              this.draftId = 0;
              if(lastestFormData.basic.id==formId){
                this.formData = lastestFormData
              }else{
                this.$message.warning('您发起的流程已被修改，请重试')
                this.reloadPath()
              }
            }
          }
        });
    },
    reset() {
      // TODO 这里是否需要调重置草稿的接口
      this.$confirm("表单重置后无法恢复，是否确定重置？", "重置")
        .then(() => {
          this.$refs.form.reset();
        })
        .catch((err) => {
          // TODO
          // console.log(err);
        });
    },
    getDescription(list) {
      let description = ''
      for(let i = 0; i < list.length; i++) {
        if (list[i].subType === 'shop' && list[i].value) {
          if (Array.isArray(list[i].value)) {
            description = list[i].value[0].value
          } else {
            description = list[i].value.value
          }
          return description
        }
        if (list[i].type === 'subfield') {
           if (this.getDescription(list[i].children)) {
            return this.getDescription(list[i].children)
           }
        }
      }
    },
    submit() {
      this.$refs.form.validate().then(() => {
        let formList = this.formData.form.formSetting
        let noSchedules = formList.find(item => item.type === 'approve' && !item.value.activitySchedules.length)
        let noNames = formList.find(item => item.type === 'approve' && !item.value.activityNames.length)
        let noCoupons = formList.find(item => item.type === 'approve' && !item.value.coupons.length)
        if (noSchedules) {
          this.$message.error('请选择活动档期')
          return
        }
        if (noNames) {
          this.$message.error('请选择活动名称')
          return
        }
        if (noCoupons) {
          this.$message.error('请选择优惠券')
          return
        }
        if(this.processId){
          this.updateNoProcess();
          return ;
        }
        const flow = this.formData.bpm[this.$refs.form.bpmPriorityIndex];
        const params = {
          content: this.formData,
          activityKey: (flow||'')&&flow.activitiKey,
          activityNodeDetails: flow?flow.nodeInfo:[],
          basicId: this.formData.basic.id,
        };
        let description = this.getDescription(this.formData.form.formSetting)
        params.content.description = description || ''
        const p = this.$route.params.id
          ? this.$api.dynamicForm.updateAndActivate({
              id: this.$route.params.id,
              ...params,
            })
          : this.$api.dynamicForm.submit({
              id: this.draftId,
              ...params,
            });
        p.then(({ code, msg }) => {
          if (code == 200) {
            this.$message.success(msg);
            this.back();
          }else{
            this.$message.error(msg)
            if(code == 450){
              this.reloadPath()
            }
          }
        }).catch(err=>{
          // this.$message.error(err);
        });
      });
    },
    getForm() {
      return new Promise((resolve, reject) => {
        this.$api.dynamicForm
            .externalForm(this.$route.params.formId)
            .then(({ data }) => {
              this.approveCode = data.basic.code
              data.form.formSetting = Object.values(data.form.formSetting);
              resolve(data)
            });
      })
    },
    updateNoProcess(){
      const param = {
        UpdateApproveData:{
          ...this.noProcessObj,
          ...{content:this.formData}
        },
        dataId:this.$route.params.id
      }
      let description = this.getDescription(this.formData.form.formSetting)
      param.UpdateApproveData.content.description = description || ''
      this.$api.dynamicForm.updateNoProcessData(param).then(({ code, msg })=>{
        if (code == 200) {
          this.$message.success(msg);
          this.back();
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.dynamic-form-edit {
  background-color: white;
  padding: 16px;
  padding-right: 0;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  .form-wrapper {
    overflow: auto;
    padding-top: 16px;
  }
  .operations {
    width: calc(100% - 240px);
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 240px;
    right: 0;
    background-color: white;
    padding: 12px;
    border-top: 1px solid #e3e3e3;
  }
}
</style>