<template>
  <div class="dynamic-form-with-flows" v-if="formData">
    <dynamic-form
      ref="form"
      @update="update"
      :approveDataId="approveDataId"
      :excludeApprovalId="excludeApprovalId"
      :approveCode="approveCode"
      v-model="formData.form.formSetting"
    ></dynamic-form>
    <flow v-if="formData.basic.hasBpm&&formData.bpm[bpmPriorityIndex]"
      :flows="formData.bpm[bpmPriorityIndex].nodeInfo"
      @updateFlows="updateFlows"
    ></flow>
  </div>
</template>

<script>
import DynamicForm from "@/components/dynamicForm/index.vue";
import Flow from "@/components/flow";
import { compare } from "./utils.js";
import {flatten} from "../components/dynamicForm/utils/utils";
export default {
  name: "DynamicFormWithFlows",
  components: { DynamicForm, Flow },
  props: {
    value: [Object],
    approveDataId: [Number, String],
    excludeApprovalId: [Number, String],
    approveCode: [Number, String],
  },
  computed: {
    formData: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    bpmPriorityIndex() {
      return this.formData.bpm.findIndex(
        (e) => e.priorityId == this.bpmPriorityId
      );
    },
    bpmPriorityId() {
      if(!this.formData.basic.hasBpm) return 0;
      // TODO 根据表单填写的值，自动选择相应的流程
      // 在这里匹配到应该选择的流程优先级id
      let res = 0;
      if (this.formData) {
        const flows = Object.entries(this.formData.form.bpmCondition)
          .filter(([priorityId]) =>
            // 过滤出有满足条件的审批流程（有审批节点）
              (this.formData.bpm
              .find((ele) => ele.priorityId == priorityId)||{nodeInfo:[]})
              .nodeInfo.some(
                (e) => (e) =>
                  // 有审批节点 && (发起人自选 || 非自选，但已经选择了审批人)
                  e.nodeType == 1 && (e.isOwnSetting != 1 || e.users)
              )
          )
          .filter(
            // 过滤出表单值能匹配的流程
            ([priorityId, { dataIndex, dataList, fieldId }]) => {
              let value = (
                flatten(this.formData.form.formSetting).find((e) => e.id == fieldId) ||
                {}
              )[fieldId.includes('computed')?'computed_value':'value'];
              if(value==''||value==undefined||value==null) return false;
              const condition = dataList[dataIndex];
              if (condition) {
                if (typeof condition == "string") {
                  if (value == condition) {
                    return true;
                  }
                } else if (typeof condition == "object") {
                  return compare(condition.comparison, condition.value, value);
                }
              }
            }
          )
          .map((e) => ({ priorityId: e[0], order: e[1].order }));
        if (!flows.length) {
          // 一个都匹配不到，则选择优先级最高的
          res = Object.entries(this.formData.form.bpmCondition).reduce(
            (acu, [key, value]) => {
              if (value.order <= (acu.order)) {
                return { ...value, priorityId: key };
              } else {
                return acu;
              }
            },
            {order:9999,priorityId:''}
          ).priorityId;
        } else if (flows.length == 1) {
          // 只匹配到一个，则选择匹配到的那一个
          res = flows[0].priorityId;
        } else {
          // 匹配到多个，则选择匹配到的里面最先级最高的
          const minOrder = flows.reduce((acu, ele) => {
            if (ele.order < acu) {
              return ele.order;
            } else {
              return acu;
            }
          }, 99999);
          res = flows.find(e=>e.order==minOrder).priorityId
        }
      }
      return res;
    },
  },
  methods: {
    update() {
      this.$emit("update");
    },
    updateFlows(val) {
      this.formData.bpm[this.bpmPriorityIndex].nodeInfo = val
      this.update();
    },
    reset() {
      // TODO 这里还需要重置流程
      this.$refs.form.reset();
    },
    validate() {
      return new Promise((res, rej) => {
        this.$refs.form.validate().then(res).catch(rej);
      });
    },
    getFlow() {
      return this.formData.bpm[this.bpmPriorityIndex].nodeInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.dynamic-form-with-flows {
  overflow: auto;
  padding-top: 16px;
}
</style>